import { FormEventHandler, useState, useEffect, useCallback } from 'react';
import { EnvelopeIcon, LockClosedIcon, EyeSlashIcon, EyeIcon } from '@heroicons/react/24/outline';
import type { NextPage } from 'next';
import { signIn } from 'next-auth/react';
import Head from 'next/head';
import Image from 'next/image';
import logoDark from 'assets/logoWhite.png';
import logo from 'assets/logo.png';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useSession } from 'next-auth/react';

const SignIn: NextPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();
  const { status } = useSession();

  useEffect(() => {
    if (status === 'authenticated') {
      setTimeout(() => {
        router.push('/customer/dashboard').catch(console.error);
      }, 100);
    }
  }, [status, router]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();
      const result = await signIn<'credentials'>('customer', {
        callbackUrl: '/customer/auth/signin',
        redirect: false,
        email,
        password,
      });
      if (!result) return;

      if (!result.ok) {
        return alert('Invalid email or password');
      }

      await router.push('/customer/dashboard');
    },
    [email, password, router]
  );

  return (
    <div className="flex justify-center items-center h-screen transition">
      <Head>
        <title>LaneShark Customer Portal</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.png" />
      </Head>
      <main className="flex w-full h-screen bg-neutral-100 dark:bg-neutral-900 justify-center">
        <div className="max-w-xl flex flex-col justify-start mt-48">
          <div className="flex flex-col justify-center items-center p-8">
            <div className="border-b-2 border-black dark:border-brand pb-8">
              <div className="hidden dark:block">
                <Image src={logoDark} alt="logo" />
              </div>
              <div className="dark:hidden">
                <Image src={logo} alt="logo" />
              </div>
            </div>
            <span className="-mt-4 bg-neutral-100 dark:bg-neutral-900 px-4 text-black dark:text-white rounded-full">
              Customer Portal
            </span>
          </div>
          <form onSubmit={handleSubmit} className="rounded-lg px-8">
            <div className="relative">
              <EnvelopeIcon className="h-5 w-5 text-neutral-500 absolute top-4 left-3" aria-hidden="true" />
              <input
                name="email"
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase());
                }}
                className="lowercase text-black dark:text-white shadow-inner border-b dark:border-neutral-800 border-neutral-200 placeholder:text-neutral-500 rounded-lg w-full pl-10 p-3 mb-4 focus:border-brand focus:ring-brand dark:focus:border-neutral-700 dark:focus:ring-neutral-700 dark:bg-neutral-800"
              ></input>
            </div>
            <div className="relative">
              <LockClosedIcon className="h-5 w-5 text-neutral-500 absolute top-4 left-3" aria-hidden="true" />
              <input
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="text-black dark:text-white shadow-inner border-b dark:border-neutral-800 border-neutral-200 placeholder:text-neutral-500 rounded-lg w-full pl-10 p-3 mb-4 focus:border-brand focus:ring-brand dark:focus:border-neutral-700 dark:focus:ring-neutral-700 dark:bg-neutral-800"
              ></input>
               <div 
        className="absolute bottom-3 right-1 inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? (
          <EyeIcon  className="h-5 w-5 text-neutral-500" />
        ) : (
          <EyeSlashIcon className="h-5 w-5 text-neutral-500" />
        )}
      </div>
            </div>
            <div className="flex justify-between items-center">
              <button
                type="submit"
                className="w-full px-8 py-3 border-2 border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white hover:text-brand bg-neutral-800 hover:bg-black"
              >
                Sign In
              </button>
            </div>
          </form>
          <div className="px-8">
            <div className="flex justify-center px-4 mt-8 mb-4">
              <span className="flex-grow border-b border-black opacity-20 mb-3" />
              <span className="mx-2 text-lg">or</span>
              <span className="flex-grow border-b border-black opacity-20 mb-3" />
            </div>
            <div className='text-center w-full mb-2 text-black'>Don&apos;t have an account with Lane Shark?</div>
            <Link passHref href="/customer/auth/signup"><button className="w-full px-8 py-3 border-2 border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white hover:text-brand bg-neutral-800 hover:bg-black">
              Sign up
            </button>
            </Link>
            <div className="mt-4 flex justify-between">
            <Link href="https://lanesharkusa.com/policy/terms">
                <a target="_blank" className="text-neutral-500 dark:text-neutral-400 text-sm underline">Terms & Conditions</a>
              </Link>
              <Link href="/customer/auth/password-reset">
                <a className="text-neutral-500 dark:text-neutral-400 text-sm underline">Forgot your password?</a>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SignIn;
